import React, { useState, useRef, useEffect } from "react";
import { FaChevronUp } from "react-icons/fa";
import { PiWindmill } from "react-icons/pi";

const teamMembers = [
  {
    name: "Charles Liebenberg",
    position: "Founder, CEO",
    image: "/images/Team/CL.jpeg",
    bio: "Charles, a London-based energy entrepreneur, has 34 years in finance and energy. He founded power finance divisions at major banks, raising $1.5B in debt and handling $400M in transactions across Africa. In 2004, he established South Africa's first IPP, BioTherm Energy. With 20 years in C-suite roles, Charles has significantly impacted the African energy sector through project finance and development. Charles holds BCom(Hons), H.Dip.Tax and MBA degrees.",
  },

  {
    name: "Gorden Walters",
    position: "NED",
    image: "/images/Team/GORDEN.jpg",
    bio: "Gorden has 30 years of experience in the SADC energy sector. He began at NamPower, Namibia's national electricity utility, rising to group CFO. His expertise covers electricity generation, transmission, and distribution. Gorden established and grew two profitable regional distribution companies in Namibia. For the past 12 years, he's worked in the private energy sector, holding senior positions in finance and M&A. He advises Namibian parastatals on energy strategies and maintains a wide network in SADC energy, regulatory, and public sectors. Gorden holds BCom and BCompt (Hons) degrees and is a registered professional accountant.",
  },
  {
    name: "John Less",
    position: "CDO",
    image: "/images/Team/JOHN.jpg",
    bio: "John L. has over 15 years of energy sector development experience. He was the South African Country GM of Comverge, a Nasdaq listed energy company. Supporting USAID, he closed a $150m PCG providing the first DFI guarantee to facilitate SADC private energy trading in 2017. At Symbion Power, he developed solar, biomass, and crude-to-power projects in East African and Madagascar. As a Power Africa transaction advisor, he advised several regional IPPs in Southern Africa. Prior, John L. was a City Director for the Large Cities Climate Leadership Group, a sustainable infrastructure lead for the Clinton Climate Initiative, and a consultant with Sustainable Finance. He has an MA in International Economics from Johns Hopkins SAIS.",
  },

  {
    name: "Eslam Samy",
    position: "Director, Project Finance",
    image: "/images/Team/ESLAM.jpg",
    bio: "Eslam has over 15 years of experience as a financial advisor in the energy sector, specializing in M&A and project finance. He began his career as a graduate accountant and spent over a decade with PwC and EY in London, including a role in EY's EMEA M&A Energy Centre of Excellence. Eslam has advised on more than 50 energy sector transactions. Currently London-based, he works as a freelance advisor for clients across five countries, focusing on Middle East and African energy sectors. He also supports Shell's Renewable Energy deal team. Eslam is fluent in English and Arabic, with competent French. He holds BA Accounting & Finance and MBA degrees and is a chartered certified accountant.",
  },
  {
    name: "Haytham Sabor",
    position: "Director, Business Development",
    image: "/images/Team/HAYTHAM.jpg",
    bio: "Haytham has 17 years of experience in business development and project management for water and renewable energy projects in the Middle East and Africa. He led the financial close of solar PV plants in Malawi and developed wind projects in Morocco and other African countries. At Abengoa, he completed the development of a major desalination plant in Morocco. Haytham is multilingual, speaking Arabic, English, French, and other languages. He holds B.Eng and MBA degrees.",
  },
  {
    name: "Christian Wright",
    position: "Strategic Advisor",
    image: "/images/Team/CW.jpg",
    bio: "Christian has 25+ years of experience in developing, financing, and operating IPPs across Africa. He worked at AES on major power projects in Uganda, Tanzania, Nigeria, and Cameroon. At Aldwych International, he developed projects including Lake Turkana Wind Power in Kenya. With Globeleq, he developed solar projects and launched tenders in East Africa. After serving as CEO of Elsewedy Energy, he now consults for Blue International on African IPP and mining operations. Christian has lived in several African cities, London, and is currently Dubai-based. He holds degrees from Cambridge University.",
  },
  {
    name: "Maaike Friedeman",
    position: "Senior advisor: West Africa; Commercial & Industrial solar",
    image: "/images/Team/MAAIKE.jpg",
    bio: "Maaike has 21 years of experience in gas, LNG, and renewable power sectors across sub-Saharan Africa, Europe, and Asia. She led Shell's power in emerging markets business, developing renewable projects in Africa and Southeast Asia. Previously, she managed urban solar macro grid roll-outs for Rensource Energy in Nigeria and headed Shell's LNG and midstream gas commercial team in West Africa. Her earlier roles included European gas trading at Shell and managing wind project investments at Shell WindEnergy. Maaike's expertise spans business development, M&A, venture capital, corporate governance, and trading. She holds MS and MSc degrees.",
  },
];

const TeamMemberCard = ({ member }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [cardHeight, setCardHeight] = useState("auto");
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      setCardHeight(
        isExpanded ? `${contentRef.current.scrollHeight}px` : "110px"
      );
    }
  }, [isExpanded]);

  return (
    <div className="relative bg-gray-100 rounded-lg overflow-hidden shadow-md p-10">
      <img
        src={member.image}
        alt={member.name}
        className="w-full h-[28rem] object-cover rounded-lg"
      />
      <div
        className={`absolute left-0 right-0 bottom-0 py-7 md:py-5 shadow transition-all duration-300 ease-in-out overflow-hidden`}
        style={{ height: cardHeight }}
      >
        <div
          className="flex flex-col justify-between items-center bottom-4 left-4 right-4 p-5 bg-[#FEF3E2] rounded-lg transition-all duration-300 ease-in-out overflow-hidden"
          ref={contentRef}
        >
          <div className="flex flex-row w-full justify-between xl:mb-5 2xl:mb-0">
            <div>
              <h3
                className={`font-black text-[#254336] ${
                  member.name.includes("Maaike")
                    ? "text-sm md:text-md"
                    : "text-md md:text-lg"
                }`}
              >
                {member.name}
              </h3>
              {}
              <p
                className={`text-[#254336] font-semibold ${
                  member.name.includes("Maaike")
                    ? "text-xs md:text-sm mb-3"
                    : "text-sm md:text-md mb-1"
                }`}
              >
                {member.position}
              </p>
            </div>

            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="text-gray-600 hover:text-gray-800 transition-transform duration-300 ease-in-out"
              style={{
                transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
              }}
            >
              <FaChevronUp />
            </button>
          </div>
          <p className="py-5 text-sm text-[#254336]">{member.bio}</p>
        </div>
      </div>
    </div>
  );
};

const OurTeam = () => {
  return (
    <div className=" mx-auto px-5 py-5">
      <div className="text-center mb-5 md:mb-16">
        <div className=" text-[#FEF3E2] bg-[#254336] flex flex-col justify-center items-center h-auto w-full mb-5 rounded-[1.5rem] mx-auto p-10 lg:p-32">
          <div className="flex text-7xl justify-center mb-4">
            <PiWindmill />
          </div>
          <h1 className="text-xl text-left md:text-2xl xl:text-4xl 2xl:text-5xl font-bold mb-4 px-6 md:px-20">
            Having worked together for more than two decades, our team has a
            strong track record of collaboration, both personally and
            professionally.
          </h1>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 xl:gap-4 2xl:gap-8 mb-5 lg:px-32 ">
        {teamMembers.map((member, index) => (
          <TeamMemberCard key={index} member={member} />
        ))}
      </div>
    </div>
  );
};

export default OurTeam;
