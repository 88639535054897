import React from "react";
import {
  FaLeaf,
  FaNetworkWired,
  FaUserTie,
  FaMoneyBillWave,
} from "react-icons/fa";
import { SlEnergy } from "react-icons/sl";

const EnergyType = ({ title, icon, description, isActive }) => (
  <div
    className={`p-6 border-[#FEF3E2] rounded-2xl ${
      isActive ? "bg-[#254336] text-[#FEF3E2]" : "bg-white"
    }`}
  >
    <div className="flex flex-row items-center">
      <div className="xl:text-lg 2xl:text-2xl mb-2">{icon}</div>
      <h3 className="xl:text-lg 2xl:text-xl  font-black mb-2 ml-3">{title}</h3>
    </div>
    {description && (
      <p className="mb-4 xl:text-sm 2xl:text-lg text-bold">{description}</p>
    )}
    {!isActive && (
      <button className="text-blue-600 hover:underline">VIEW DETAILS</button>
    )}
  </div>
);

const OurApproach = () => {
  return (
    <div className="p-5 flex flex-col justify-center items-center md:flex-col gap-5 ">
      <div className="flex flex-row md:flex-row gap-5">
        <div className=" text-[#FEF3E2] bg-[#254336] flex flex-col justify-center items-center h-auto  rounded-[1.5rem] mx-auto py-10 px-10 lg:py-32 lg:px-72">
          <SlEnergy className="text-6xl mb-5" />
          <h1 className="text-2xl xl:text-3xl 2xl:text-6xl font-black mb-4">
            We are an African energy transition platform, investing patient
            equity in decarbonisation assets to create a sustainable future for
            businesses and communities.
          </h1>

          <div className="">
            <p className="text-lg xl:text-xl 2xl:text-2xl  mb-6">
              <span className="font-black">SIA</span> is dedicated to investing
              equity in sustainable energy transition assets at the speed and
              scale required to address Africa's energy deficit and the climate
              crisis.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse lg:flex-row md:flex-row gap-5">
        <div className=" text-[#FEF3E2] bg-[#254336] flex flex-col justify-center md:w-2/3 xl:h-[calc(100vh-6.25rem)] 2xl:h-[calc(100vh-14.25rem)] rounded-[1.5rem] mx-auto py-10 px-20 xl:px-40">
          <h1 className="text-xl xl:text-2xl 2xl:text-4xl font-bold mb-2">
            About Us
          </h1>

          <div className="">
            <p className="text-lg 2xl:text-xl mb-6">
              The SIA team has over 175 years of experience in renewable energy
              project development, infrastructure lending, investment banking,
              private equity, &amp; asset management.
              <br />
              <br />
              Our CEO raised $150m in private equity to start the first
              Independent Power Producer (IPP) in South Africa in 2003.
              <br />
              <br />
              As CFO of NamPower, our NED helped make NamPower the most bankable
              utility in Africa. Our development team together have closed
              billions in infrastructure projects across desalination, solar,
              wind, hydro, coal, gas, diesel, &amp; industrial cogeneration
              projects.
              <br />
              <br />
              Our team members have developed &gt; 1,500MW electricity
              generation projects across desalination, solar, wind, hydro, coal,
              gas, diesel, &amp; industrial cogeneration projects.
            </p>
          </div>
        </div>
        <div className="md:w-1/3 ">
          <img
            src="https://images.unsplash.com/photo-1534168800341-3b7b2408e436?q=80&w=1944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="Sustainable energy project"
            className="w-full h-[40vh] lg:h-[calc(100vh-6.25rem)] 2xl:h-[calc(100vh-14.25rem)] rounded-3xl object-cover  shadow-2xl"
          />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row md:flex-row gap-5">
        <div className="md:w-1/2 ">
          <img
            src="https://images.unsplash.com/photo-1509391366360-2e959784a276?q=80&w=3872&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="Sustainable energy project"
            className="w-full h-[40vh] lg:h-[calc(100vh-6.25rem)] 2xl:h-[calc(100vh-14.25rem)] rounded-3xl object-cover  shadow-2xl"
          />
        </div>
        <div className=" text-[#FEF3E2] bg-[#254336] flex flex-col justify-center md:w-1/2 xl:h-[calc(100vh-6.25rem)] 2xl:h-[calc(100vh-14.25rem)] rounded-[1.5rem] mx-auto py-10 px-20 xl:px-40">
          <h1 className="text-xl xl:text-2xl 2xl:text-4xl font-bold mb-2">
            Our Approach
          </h1>

          <div className="">
            <p className="text-lg 2xl:text-xl mb-6">
              We work with local African renewable energy developers to maximise
              their long-term economic interest in their projects or to help
              them exit successfully. As specialists in raising power project
              finance, structuring projects, sourcing low-cost debt, &
              negotiating EPC and O&M contracts, our experience gets deals done.
              We are nimble, flexible, practical, & will prioritise what is best
              to successfully close projects. Our local and international
              industry networks help us find the right partners for the life of
              our investments.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse lg:flex-row md:flex-row gap-5">
        <div className=" text-[#FEF3E2] bg-[#254336] flex flex-col justify-center md:w-1/3 xl:h-[calc(100vh-6.25rem)] 2xl:h-[calc(100vh-14.25rem)] rounded-[1.5rem] mx-auto  py-10  px-16 xl:px-24">
          <h1 className="text-xl xl:text-2xl 2xl:text-4xl font-bold mb-2">
            Our Value Add
          </h1>

          <div className="">
            <p className="text-lg 2xl:text-xl mb-6">
              We create value by derisking projects to appeal to a broad range
              of investors, finding win-win solutions for developers, EPCs,
              equity, and debt providers. By reducing the time and cost of
              developing projects to financial close, we create and capture
              long-term value for all our partners.
            </p>
          </div>
        </div>
        <div className="md:w-2/3 ">
          <img
            src="/images/valueadd.jpeg"
            alt="Sustainable energy project"
            className="w-full h-[40vh] lg:h-[calc(100vh-6.25rem)] 2xl:h-[calc(100vh-14.25rem)] rounded-3xl object-cover  shadow-2xl"
          />
        </div>
      </div>
      <div className="h-2 bg-[#254336] rounded-3xl w-full"></div>
      <div className=" flex flex-col justify-center items-center rounded-[1.5rem]">
        <div className="grid w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <EnergyType
            title="Specialised expertise"
            icon={<FaUserTie />}
            description="Our team members are African project finance & IPP pioneers. We have hands on Gx, Tx, and Dx investment and management experience. Our team members have closed deals in over 15 countries across all power generation technologies and can conduct the majority of the required due diligence internally."
            isActive={true}
          />
          <EnergyType
            title="Strong origination networks & market reputation"
            icon={<FaNetworkWired />}
            description="We have established reputations building some of the largest wind, desalination, and power infrastructure projects on the continent. Our strong power & finance networks across the continent help us find the right partners for each project we take on."
            isActive={true}
          />
          <EnergyType
            title="Commitment to African growth & Industrial decarbonisation"
            icon={<FaLeaf />}
            description="Every solar, hydro, or wind plant we build will help decarbonise a large employer's manufacturing processes making their products and exports more competitive and providing affordable & reliable electricity to the communities nearby."
            isActive={true}
          />
          <EnergyType
            title="Nimble, flexible capital"
            icon={<FaMoneyBillWave />}
            description="A commercial, practical approach to investment rather than a developmental or rigid fund mandate-based approach."
            isActive={true}
          />
        </div>
      </div>
    </div>
  );
};

export default OurApproach;
