import React from "react";
import { TypeAnimation } from "react-type-animation";
import { Link } from "react-router-dom";
import { LuBadgeInfo } from "react-icons/lu";

const Home = () => {
  return (
    <div>
      {/* <video
        src="https://www.dropbox.com/scl/fi/eg0c3th4vyjnnz09cwxam/22512-328261507_tiny.mp4?rlkey=sk0uvs93a3uby17qbzdx1c3cx&raw=1"
        alt="River and forest"
        className="px-5 py-5 w-full brightness-50 h-[calc(100vh-6.25rem)] object-cover rounded-[3rem] col"
        autoPlay
        loop
        muted
        playsInline
        preload="auto"
        poster="/images/hero.jpg"
      ></video> */}
      <img
        src="/images/hero.jpg"
        alt="River and forest"
        className="px-5 py-5 w-full brightness-50 h-[calc(100vh-10.25rem)] md:h-[calc(100vh-6.25rem)] object-cover rounded-[3rem] col"
      />
      <div className="relative inset-0">
        <div className="absolute bottom-0 flex flex-col items-center text-center w-full p-16">
          <h1 className="text-[#FEF3E2] text-3xl md:text-4xl lg:text-5xl 2xl:text-7xl font-black tracking-wider mb-16">
            <TypeAnimation
              sequence={[
                " We are long-term investors in & operators of African renewable energy assets, building cleaner, more reliable energy infrastructure for industry & communities",
              ]}
              wrapper="span"
              speed={25}
              style={{ display: "inline-block" }}
            />
          </h1>
          <Link to="/our-approach">
            <button className="rounded-2xl xl:text-xs 2xl:text-lg border-2 flex items-center border-[#FEF3E2] bg-[#254336] px-6 py-3 font-black text-[#FEF3E2] transition-all duration-300 hover:translate-x-[-4px] hover:translate-y-[-4px] hover:rounded-xl hover:shadow-[4px_4px_0px_black] active:translate-x-[0px] active:translate-y-[0px] active:rounded-2xl active:shadow-none">
              Explore More <LuBadgeInfo className="ml-2" />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
